import { PiCheck } from "react-icons/pi"

const CompletePage = () => {
    return (
        <div className="w-full h-screen bg-gray-50 flex flex-col">
            <div className="my-auto mx-auto flex flex-col">
                <PiCheck className="my-auto mx-auto bg-green-500 rounded-full text-6xl p-2"></PiCheck>
                <div className="mt-4 text-xl">Completed!</div>
                <div className="mx-auto underline mt-4" onClick={() => { window.location.href = "/" }}>Back to home.</div>
            </div>

        </div>
    )
}
export default CompletePage