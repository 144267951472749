import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CompletePage from './pages/CompletionPage';

function App() {
  return (
    <BrowserRouter>

      <Routes>

        <Route path="/completion" element={<CompletePage />}> </Route>
        <Route path="/" element={<HomePage />}>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
