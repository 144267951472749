import { useEffect, useRef, useState } from "react";
import joe from "../assets/joe.png"
import obama from "../assets/obama.png"
import trump from "../assets/trump.png"
import drake from "../assets/drake.png"
import axios from 'axios';
import InputMask from 'react-input-mask';

import soundwave from "../assets/animation_lk24gwhv.json"
import elon from "../assets/elon.png"

import Payment from "../payments/Payment";
import Lottie from "react-lottie-player";
const CheckOutPage = () => {
    return (
        <Payment />
    )
};
const HomePage = () => {

    const [selectedContact, setSelectedContact] = useState(null);
    const [number, setNumber] = useState('');
    const [objective, setObjective] = useState('');
    const [canMakeCall, setCanMakeCall] = useState(true)
    const [remainingCalls, setRemainingCalls] = useState(null);
    const [call_id, setCall_id] = useState(null)
    const [callStarted, setCallStarted] = useState(false)
    const [record, setRecord] = useState(false)
    // contacts array
    const [recordingURL, setRecordingURL] = useState(null)
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [authToken, setAuthToken] = useState(null);
    const [promptCell, setPromptCell] = useState(false);
    const [promptCode, setPromptCode] = useState(false);
    const [cellNumber, setCellNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showCheckout, setShowCheckout] = useState(false);
    const [transcript, setTranscript] = useState(null)
    const [callFinished, setCallFinished] = useState(false);

    const handleAddCalls = (numCalls) => {
        // Update the payment amount and initiate the checkout process
        // If successful, add the purchased calls to the remaining calls
        setShowCheckout(true);
    };

    const handlePaymentSuccess = () => {
        // Add logic to update the number of remaining calls based on successful payment
        setShowCheckout(false);
    };
    const fetchRemainingCalls = async () => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            try {
                const response = await axios.get('/api/numleft', {
                    headers: {
                        'Authorization': `${token}`
                    }
                });
                if (response.status === 200) {

                    setRemainingCalls(response.data.calls_remaining);
                } else {
                    // handle the error
                    console.error(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        } else {

        }
    };
    const verifyUser = async () => {
        if (localStorage.getItem('auth_token')) {
            const headers = {
                'Authorization': `${localStorage.getItem('auth_token')}`
            }
            try {
                const response = await axios.get('/api/verify', { headers });
                console.log(response)

            } catch (error) {
                localStorage.clear()
                console.log("do nothing")
            }


        }
    }
    // ... other functions

    useEffect(() => {
        verifyUser()
        // On component mount, check if auth_token is in localStorage
        const token = localStorage.getItem('auth_token');
        if (token) {
            setAuthToken(token);
            fetchRemainingCalls()
        } else {
            // If not, prompt the user to enter their cell number
            // setPromptCell(true);
        }
    }, []);
    const getRecording = async (call_id) => {
        const token = localStorage.getItem('auth_token');
        if (token && call_id) {



            const resp = await axios.post('/api/recording', {
                call_id: call_id
            }, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            if (resp) {
                const json = resp
                console.log(json.data)
                setRecordingURL(json?.data?.url)
            }
        }

    }
    useEffect(() => {
        let intervalId; // Declare a variable to keep the interval ID.

        if (callStarted) {
            intervalId = setInterval(async () => { // Assign the interval ID to the variable.
                try {
                    const response = await axios?.post('/api/transcript',
                        { call_id: call_id }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem('auth_token')
                        }
                    });
                    console.log(response)
                    setTranscript(response?.data?.transcripts)
                    if (response?.data?.finished) {
                        setCallFinished(true)
                        const toPass = call_id

                        clearInterval(intervalId); // Clear the interval using the interval ID.
                        await getRecording(toPass)
                        console.log("Call done!")
                    }
                } catch (error) {
                    clearInterval(intervalId); // Clear the interval using the interval ID.
                    console.error(error)
                }
            }, 2000);
        } else {
            if (intervalId) { // Check if intervalId is not undefined.
                clearInterval(intervalId); // Clear the interval using the interval ID.
            }
        }

        return () => { // Clean up function that React will run when a component unmounts.
            if (intervalId) {
                clearInterval(intervalId);
            }
        };

    }, [call_id, callStarted])

    const handleCellNumberSubmit = async () => {
        // Call your API to send the verification code to the cell number
        // Then, show the prompt for the verification code
        try {
            await axios.post('/api/signup/smsverification', {
                phoneNumber: cellNumber
            });
            setPromptCell(false);
            setPromptCode(true);
        } catch (error) {
            console.error(error);
            // Handle the error in a user-friendly way
        }
    };

    const handleVerificationCodeSubmit = async () => {
        // Call your API to validate the verification code
        // If valid, store the returned token in localStorage
        try {
            const response = await axios.post('/api/signup/verifysms', {
                phoneNumber: cellNumber,
                code: verificationCode
            });
            if (response.data.jwt) {
                localStorage.setItem('auth_token', response.data.jwt);
                setAuthToken(response.data.jwt);
                fetchRemainingCalls()
            }
            setPromptCode(false);
            setPromptCell(false)
        } catch (error) {
            console.error(error);
            // Handle the error in a user-friendly way
        }
    };
    const makeCall = async () => {
        console.log("FIRING")
        setLoading(true);
        setMessage('');
        setCallFinished(false)
        const token = localStorage.getItem('auth_token');
        if (token) {
            console.log("poop")
            if (canMakeCall) {
                try {
                    const resp = await axios.post('/api/call', {
                        phoneNumber: number,
                        objective,
                        voice: selectedContact?.num,
                        persona: selectedContact?.name,
                        record: record
                    }, {
                        headers: {
                            'Authorization': `${token}`
                        }
                    });
                    setCallStarted(true)
                    setLoading(false);
                    if (resp.status === 200) {
                        // const jsonCall = await resp.json()
                        console.log(resp)
                        setCall_id(resp?.data?.call_id)
                        setMessage('Call successful!');
                    } else {
                        setMessage('Oops, call failed');
                    }
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                    setMessage('Oops, call failed');
                }
            }
        } else {
            setPromptCell(true);
        }

        // setCanMakeCall(false)
        setLoading(false);
    }
    const endOfMessagesRef = useRef(null); // Reference to the last message
    const getCallRecording = async (call_id) => {

    }
    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [transcript]);
    const contacts = [
        { name: "Obama", img: obama, num: 3 },
        { name: "Elon", img: elon, num: 6 },
        { name: "Joe Rogan", img: joe, num: 4 },
        { name: "Drake", img: drake, num: 7 },
        { name: "Trump", img: trump, num: 5 }
    ];

    return (
        <div className="flex flex-col  h-screen bg-gray-100 relative pb-24">
            {remainingCalls === 0 && !showCheckout && (
                <div className="text-center border border-gray-300 rounded-md w-2/3 mx-auto bg-white text-gray-500" onClick={() => handleAddCalls(3)}>
                    <p>You have run out of calls. Please purchase more to continue.</p>
                    <button onClick={() => handleAddCalls(3)}>Add 5 Calls for $1.99</button>

                </div>
            )}

            {showCheckout && (
                CheckOutPage()
            )}
            {!showCheckout && <>
                <div className="flex flex-row md:py-0 md:px-0 py-8 px-4 md:mb-4 mb-0">


                    <div className="flex flex-col md:bg-white md:w-full md:px-4 md:py-4 py-0 py-4  md:shadow-md shadow-none">
                        <h1 className="text-green-500 text-3xl font-bold mb-4 my-auto text-left md:text-center">Prank <span className="italic">GPT</span></h1>
                        <h1 className="text-gray-500 text-lg font-light mx-auto mb-4">AI powered calls with voices anyone can recognize.</h1>
                    </div>
                    <div className="h-12 w-[0.5px] bg-red-400 my-auto mx-2"></div>
                    <div className="my-auto">

                        {remainingCalls && <>
                            <div className="text-center text-5xl text-green-500 ">{remainingCalls ? remainingCalls : 3}</div>
                            <p className="text-center mt-2"> remaining calls.</p>
                            <p className="text-sm underline mt-4" onClick={() => { handleAddCalls(3) }}>Get 5 More</p></>
                        }
                    </div>
                </div>


                <div className="w-full">
                    <div>

                    </div>

                    <div className="flex flex-row space-x-2 overflow-x-scroll w-full md:w-1/2 mx-auto md:my-12 px-2">


                        {contacts.map(contact => (
                            <div className="flex flex-col">
                                <button
                                    key={contact?.name}
                                    className={` bg-white w-11/12 text-gray-900 py-2 rounded-none mb-2 hover:text-gray-100 border border-gray-200 ${selectedContact?.name === contact?.name && 'border border-green-400 bg-green-100 flex flex-col'}`}
                                    onClick={() => setSelectedContact(contact)}
                                >
                                    <div className="flex flex-col w-36">
                                        <div className="flex">
                                            <div className="my-auto mx-auto w-16 h-16 ">
                                                <img src={contact?.img} className="object-fit rounded-sm "></img>
                                            </div>
                                        </div>
                                        <div className="flex flex-col text-center mt-2">
                                            <div className="mx-auto">
                                                <div className={`rounded-none text-white px-2 text-xs ${selectedContact?.name === contact?.name && ' bg-green-600 text-green-100'} bg-gray-400 `}>Top Voice</div>
                                            </div>
                                        </div>


                                    </div>

                                </button>
                                <div className="text-left px-2 text-gray-500">
                                    {contact.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {selectedContact && (
                    <div className=" p-4 rounded text-gray-800 h-full relative w-full md:w-2/3 mx-auto">

                        <h2 className="text-xl font-bold mb-2">Call From {selectedContact.name}</h2>
                        <div className="border p-2 w-full mb-2 rounded-none w-full flex space-x-2 bg-white">
                            <div>+1</div>
                            <input
                                value={number}
                                className="focus:outline-none"
                                onChange={e => setNumber(e.target.value.replace(/\D/g, ''))}
                                placeholder="Friends Number"
                            />
                        </div>
                        <textarea
                            className="border p-2 w-full mb-2"
                            value={objective}
                            onChange={e => setObjective(e.target.value)}
                            placeholder="Prank?"
                            maxLength={164}
                        />

                        <div className="flex items-center">
                            <label className="relative inline-block w-12 h-6">
                                <input
                                    type="checkbox"
                                    className=""
                                    checked={record}
                                    onChange={(e) => setRecord(e.target.checked)}
                                />

                            </label>
                            <label className="ml-2">Record?</label>
                            <p className="text-xs text-gray-500 ml-2">Record the call? Be aware that it is your responsibility to obtain permission from the end user.</p>
                        </div>
                        {loading ? (
                            <div className="animate-spin w-8 h-8 border-4 border-dashed rounded-full border-green-500 mx-auto my-10"></div>
                        ) : (
                            <div
                                className="w-full absolute bottom-0 left-0 text-white px-4 py-2 rounded flex"
                                onClick={() => { }}
                            >
                                <button className="mx-auto bg-green-500 py-2 px-2 rounded-none w-1/2" disabled={!number || !objective} onClick={() => {

                                    makeCall()

                                }}>
                                    Call Now
                                </button>

                            </div>
                        )}

                        {message && <p className="mt-4 text-center">{message}</p>}

                    </div>)}
                {promptCell && (
                    <div className="h-screen w-full absolute bg-green-500 top-0 left-0 flex transition-all duration-1000">
                        <div className="mx-auto my-auto flex flex-col w-3/4">
                            <div className="w-full text-center text-white mx-auto my-12 font-bold text-xl">Please verify your phone first. We won't share this with anyone else.</div>
                            <InputMask mask="999-999-9999" value={cellNumber} className="my-12 w-full mx-auto text-center bg-white bg-opacity-0 text-white text-3xl focus:outline-blue-300 placeholder:text-white" onChange={e => setCellNumber(e.target.value)} placeholder="Type your cell number" />
                            <button className="py-2 bg-green-300 rounded-md mt-4" onClick={handleCellNumberSubmit}>Submit</button>
                        </div>
                    </div>
                )}
                {promptCode && (
                    <div className="h-screen w-full absolute bg-green-500 top-0 left-0 flex transition-all duration-1000">
                        <div className="mx-auto my-auto flex flex-col">
                            <InputMask className="w-full mx-auto text-center bg-white bg-opacity-0 text-white text-3xl focus:outline-none placeholder:text-white" mask="9999" value={verificationCode} onChange={e => setVerificationCode(e.target.value)} placeholder="Enter verification code" />
                            <button className="py-2 bg-green-300 rounded-md mt-4" onClick={handleVerificationCodeSubmit}>Submit</button>
                        </div>
                    </div>
                )}
                {callStarted && (
                    <div className="w-full h-screen absolute z-50 top-0 left-0 bg-gray-100 flex">
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col overflow-y-scroll my-auto h-5/6 bg-gray-100 w-3/4 mx-auto border-[0.2px] border-gray-400">
                                {transcript?.map((key, idx) => {
                                    return (
                                        <div className="rounded-md p-2 my-1 font-mono text-gray-500">
                                            <span className={key?.user === "user" ? "text-purple-500" : " text-green-400" + " pr-2"}>{key?.user}</span>: {key?.text}
                                        </div>
                                    )
                                })}
                                <div ref={endOfMessagesRef} />
                                {callFinished && <div className="text-center">Call ended!</div>}
                            </div>
                            <div>
                                {!callFinished && <Lottie
                                    animationData={soundwave}
                                    className="mx-auto my-auto mb-4"
                                    play
                                    style={{ width: 100, height: 100 }}
                                />}
                                {
                                    callFinished && recordingURL && <div className="flex flex-col items-center justify-center p-4">
                                        <audio controls className="w-full max-w-lg">
                                            <source src={recordingURL} type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                }
                            </div>
                            <div className="mx-auto border bg-gray-300 border-gray-300 px-2 py-2 my-4 rounded-none z-50" onClick={() => { setCallStarted(false); setCallFinished(true) }}>Home</div>

                        </div>
                    </div>
                )}
            </>}
        </div>
    );
}

export default HomePage;